import React from "react"
import { FooterWithCTA, SEO, Layout, Hero } from "@components"
import Content from "@components/pages/tutorials/Content.js"
import tutorialsData from "@components/pages/tutorials/Data";

function getTutorialsCount() {
  let totalTutorialsCount = 0;
  const tutorialCatKeys = Object.keys(tutorialsData);
  for (let index = 0; index < tutorialCatKeys.length; index++) {
    const catKey = tutorialCatKeys[index];
    const catItemsLength = tutorialsData[catKey].length;
    totalTutorialsCount += catItemsLength;
  }
  return totalTutorialsCount;
}

const TutorialsPage = () => {
  return (
    <>
      <Layout>
        <SEO
          title={`${getTutorialsCount()} DynamoDB Tutorials - From Beginner to Pro`}
          description="Learn DynamoDB the easy way. This post contains 50+ DynamoDB tutorials including video courses, how-to's and getting started guides."
          canonical={"https://dynobase.dev/dynamodb-tutorials/"}
        />
        <Hero
          title={"DynamoDB Tutorials - Everything You Need To Master It"}
          subtitle="Collection of tutorials and articles to help you solve problems, make decisions and understand DynamoDB."
        />
        <Content />
        <FooterWithCTA />
      </Layout>
    </>
  )
}

export default TutorialsPage
