import React from "react"
import { Flex, Image } from "rebass"

const TutorialList = ({ data, categoryName, href }) => (
  <Flex flexWrap="wrap" justifyContent="space-between">
    {data.map((tutorial, index) => (
      <Flex key={index} width={[1, 0.5, 0.31]} className="tutorial-card" mb={10}>
        <a
          href={tutorial.link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Flex flexDirection="column">
            <Image
              src={tutorial.img}
              style={{ width: "80px", marginBottom: "25px", marginTop: "5px" }}
            />
            <h4
              style={{
                lineHeight: "1.2",
                marginTop: "6px",
                marginBottom: "5px",
                fontSize: "1.2em",
                color: "black",
              }}
            >
              {tutorial.title}
            </h4>
            <span className="filter-choice-small">
              <p style={{ marginBottom: 0 }}>{categoryName}</p>
            </span>
            <p style={{ color: "#333", fontSize: "0.85em" }}>
              {tutorial.description}
            </p>
          </Flex>
        </a>
      </Flex>
    ))}
  </Flex>
)

export default TutorialList
