import React from "react"
import { Flex } from "rebass"

const choices = [
  {
    href: "#core",
    label: "Core Concepts",
  },
  {
    href: "#comparison",
    label: "Comparison",
  },
  {
    href: "#administration",
    label: "Administration",
  },
  {
    href: "#table-creation",
    label: "Table Creation",
  },
  {
    href: "#querying",
    label: "Querying",
  },
  {
    href: "#examples",
    label: "Query Examples",
  },
  {
    href: "#data",
    label: "Working with Data",
  },
  {
    href: "#video-courses",
    label: "Video Courses",
  },
]

const TutorialsPageFilter = () => (
  <Flex
    justifyContent={["center", "center"]}
    flexWrap="wrap"
    px={[0, 0, 40]}
  >
    {choices.map((choice, index) => (
      <a
        href={choice.href}
        className="filter-choice"
        key={choice.href.replace("#", "")}
      >
        <p style={{ marginBottom: 0 }}>{choice.label}</p>
      </a>
    ))}
  </Flex>
)

export default TutorialsPageFilter
