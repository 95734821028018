import React from "react"
import { Container } from "@components"
import { Box } from "rebass"
import data from "./Data"
import TutorialList from "./TutorialList"
import Headline from "@components/aggregation/Headline.js"
import Filter from "./Filter"

const Content = () => (
  <Container>
    <Box mt={[32, 62]}>
      <Filter />
      <Headline headline={"Core Concepts"} id="core" />
      <TutorialList data={data.core} categoryName="Core Concepts" href="core" />

      <Headline headline={"Comparison"} id="comparison" />
      <TutorialList data={data.comparison} categoryName="Comparison" href="comparison" />

      <Headline headline={"Administration"} id="administration" />
      <TutorialList data={data.administration} categoryName="Administration" href="administration" />

      <Headline headline={"Table Creation"} id="table-creation" />
      <TutorialList
        data={data.tableCreation}
        categoryName="Table Creation"
        href="table-creation"
      />

      <Headline headline={"Querying"} id="querying" />
      <TutorialList data={data.query} categoryName="Querying" href="querying" />

      <Headline headline={"Query Examples"} id="examples" />
      <TutorialList data={data.examples} categoryName="Query Examples" href="examples" />

      <Headline headline={"Working With Data"} id="data" />
      <TutorialList
        data={data.data}
        categoryName="Working With Data"
        href="data"
      />

      <Headline headline={"Video Courses"} id="video-courses" />
      <TutorialList
        data={data.courses}
        categoryName="Video Courses"
        href="video-courses"
      />
    </Box>
    <p style={{ marginTop: "40px" }}>
      Icons provided by{" "}
      <a href="https://app.streamlineicons.com/">Streamline icons</a>
    </p>
  </Container>
)

export default Content
